import React from 'react';
import { graphql } from 'gatsby';

import { Page } from '../components/layout';

//import styles from './imprint.module.scss';

const Imprint = ({
  data: {
    markdownRemark: {
      fields: {
        slug
      },
      html
    }
  }
}) => (
  <Page 
    slug={slug}
    body={html}
    showContact={false}>
  </Page>
);

export const imprintQuery = graphql`
  query imprintQuery($remarkId: String!) {
    markdownRemark(id: { eq: $remarkId }) {
      fields {
        slug
      }
      html
    }
  }
`;

export default Imprint;